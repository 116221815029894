import React from "react";
import Submitform from "./Submitform/Submitform";
import "./ContactContent.css";

function ContactContent() {
  return (
    <div className="contact-content">
      <h1>Get in touch with us at PharmaZ Consulting!</h1>
      <p>We are happy to answer your questions, fill out the form below or use the contact information to reach us.</p>
      <h2>Email</h2>
      <p>zacharias@pharmazconsulting.com</p>
      <h2>Telephone</h2>
      <p>+46(0)70 232 55 57</p>
      <Submitform />
    </div>
  );
}

export default ContactContent;



