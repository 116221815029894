import "./Footer.css";
import logo from "../Navbar/Loggan.png";
import { Link } from "react-router-dom";
function Footer() {
  function ToTop() {
    window.scrollTo(0, 0);
  }
  return (
    <>
      <hr className="footer-hr"/>

      <footer className="footer">
        <div className="footer-logo">
          <img
            className="mx-auto"
            src={logo}
            alt="Logo"
            />
        </div>

        <nav className="footer-nav">
          <Link
            onClick={ToTop()}
            to="/contact"
            style={{ textDecoration: "none" }}>
            <div className="contactbutton">
              <p>Contact Us</p>
            </div>
          </Link>

          <p className="p-2">
            <i>Web Page Made By Simon Thor</i>
          </p>
        </nav>
      </footer>
    </>
  );
}

export default Footer;
