import React from "react";
import "./Navbar.css";
import logo from "./Loggan.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="header">
        <div className="logo">
          <Link to="/">
            <img
              alt="LOGO"
              src={logo}
            />
          </Link>
        </div>

      <ul className="main-nav">
        <li>
          <Link to="/">HOME</Link>
        </li>
        <li>
          <a href="#about-section">ABOUT US</a>
        </li>
        <li>
          <a href="#services-section">SERVICES</a>
        </li>
        <li>
          <Link to="/contact">CONTACT</Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
