import React from "react";
import "./Services.css";

function Services() {
  return (
    <section
      className="services-section"
      id="services-section">
      <h3>Services</h3>

      <p>
        At PharmaZ, we are dedicated to empowering businesses in the
        radiopharmaceutical life science industry by providing comprehensive
        consulting services within the field of, but not limited to, the
        following:
      </p>

      <div className="serviceboxes">
        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/Quality_Assurance.jpg"
            alt=""></img>

          <h3 className="servicediv-text">Quality Assurance</h3>
        </div>

        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/GMP_Compliance.jpg"
            alt=""></img>
          <h3 className="servicediv-text"> GMP compliance</h3>
        </div>

        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/Consultant_Qualified_Person.jpg"
            alt=""></img>
          <h3 className="servicediv-text">Consultant Qualified Person</h3>
        </div>

        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/Audits.jpg"
            alt=""></img>

          <h3 className="servicediv-text">Audits</h3>
        </div>

        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/Validation_Documentation.jpg"
            alt=""></img>
          <h3 className="servicediv-text">Validation Documentation</h3>
        </div>

        <div
          className="hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/Regulatory_Affairs.jpg"
            alt=""></img>

          <h3 className="servicediv-text">Regulatory Affairs</h3>
        </div>

        <div
          className="center-box hiddenY servicediv">
          <img
            className="servicediv-picture"
            src="/Assets/Pictures/SOP_Writing.jpg"
            alt=""></img>

          <h3 className="servicediv-text">SOP writing</h3>
        </div>
      </div>
    </section>
  );
}

export default Services;
