import React from "react";
import "./Submitform.css";
import emailjs from "@emailjs/browser";

function Submitform() {
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_PUBLIC_KEY
    );
  };

  function ReloadLocation() {
    window.location.reload();
    window.scrollTo(0, 0);
  }

  return (
    <div className="submit-form">
      <form onSubmit={sendEmail}>
        <div className="name">
          <input
            type="text"
            className="name-input"
            id="fname"
            name="fname"
            placeholder="First name*"
            required></input>
          <input
            type="text"
            className="name-input"

            id="lname"
            name="lname"
            placeholder="Last name*"
            required></input>
        </div>

        <div className="email">
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email*"
            required></input>
        </div>

        <div className="question">
          <textarea
            id="question"
            name="question"
            placeholder="How can we help you?*"
            required></textarea>
        </div>

        <div className="buttons">
          <button
            type="submit"
            onClick={ReloadLocation}>
            Send
          </button>
          <button
            type="reset"
            onClick={ReloadLocation}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
}

export default Submitform;
