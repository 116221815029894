import React from "react";
import Navbar2 from "../Navbar/Navbar2.js";
import Footer from "../Footer/Footer.js";
import ContactContent from "../ContactContent.js";
import "./Contact.css";

function Contact() {
  return (
    <div className="container">
      <Navbar2 />
      <ContactContent />
      <Footer />
    </div>
  );
}

export default Contact;
