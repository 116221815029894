import React, { useEffect } from "react";
import "./HomeContent.css";
import Services from "./ServiceSection/Services";

function HomeContent() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        }
      });
    });

    const hiddenElementsYaxis = document.querySelectorAll(".hiddenY");
    hiddenElementsYaxis.forEach((el) => observer.observe(el));
  });

  return (
    <div className="HomeContent">
      <section
        className="textsections"
        id="about-section">
        <h3>PharmaZ Consulting</h3>
        <p>
          PharmaZ is a premier small consulting firm specializing in providing
          comprehensive solutions in the life science industry. With a dedicated
          focus on quality assurance and GMP compliance, we strive to assist our
          clients in achieving regulatory excellence and operational efficiency.
          <br></br>
          <br></br>
          At PharmaZ, we understand the unique challenges and requirements faced
          by companies operating in the dynamic field of pharmaceutical
          manufacturing. We combine deep industry knowledge with proven
          expertise to deliver tailored solutions that meet{" "}
          <em>your specific needs</em>.<br></br>
          <br></br>
          Our core competency lies in the radiopharmaceutical sector, where we
          help clients navigate the intricacies of this highly regulated and
          rapidly evolving field. Whether you are a manufacturer, distributor,
          or healthcare institution involved in radiopharmaceutical production
          or utilization, our consultants possess the specialized knowledge to
          guide you towards compliance, safety, and quality excellence.
          <br></br>
          <br></br>
          We offer a range of services designed to address the diverse needs of
          our clients. Our comprehensive GMP compliance services cover
          everything from quality system development and implementation to risk
          management, validation, audits, and regulatory affairs. We work
          closely with you to identify gaps, develop robust strategies, and
          implement sustainable solutions that align with global regulatory
          requirements and industry best practices.
          <br></br>
          <br></br>
          We pride ourselves on our commitment to delivering exceptional client
          service. We understand the importance of building long-term
          partnerships, and our collaborative approach ensures that we work
          alongside your team to achieve shared goals. We strive for excellence
          in every project we undertake, delivering reliable results that have a
          positive impact on your business.
        </p>
      </section>

      <Services />
    </div>
  );
}

export default HomeContent;
