import "./Hero.css";
import logo from "./Logga_vit_horizontell-noBG.png";

function Hero() {
  return (
    <div className="hero">
      <div className="heroText">
        <img
          className="herologo"
          alt="LOGO"
          src={logo}></img>
      </div>
      <img
        alt=""
        className="heroImg"
        src="https://images.unsplash.com/photo-1614935151651-0bea6508db6b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1850&q=80">
      </img>
    </div>
  );
}
export default Hero;
