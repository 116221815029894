import React from "react";
import logo from "./Loggan.png";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className="header">
      <h1>
        <div className="logo">
          <Link to="/">
            <img
              alt="LOGO"
              src={logo}
            />
          </Link>
        </div>
      </h1>

      <ul className="main-nav">
        <li>
          <Link to="/">HOME</Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
