import Navbar from "../Navbar/Navbar.js";
import Footer from "../Footer/Footer.js";
import HomeContent from "../HomeContent.js";
import "./Home.css";
import Hero from "../Hero.js";

function Home() {
  return (
    <div className="container">
      <Navbar />
      <Hero />
      <HomeContent />
      <Footer />
    </div>
  );
}

export default Home;
