import { Route, Routes } from "react-router-dom";
import Home from "./components/Pages/Home.js";
import Contact from "./components/Pages/Contact.js";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}></Route>

      <Route
        path="/contact"
        element={<Contact />}>
        {" "}
      </Route>
    </Routes>
  );
}

export default App;
